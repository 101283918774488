import NavbarDesktop from './NavbarDesktop/NavbarDesktop';
import './Navbar.scss'

const Navbar = () => {

    return (
        <>
            <div className="Navbar__container">
                {
                    <NavbarDesktop />
                }
            </div>
        </>
    );
}

export default Navbar;

