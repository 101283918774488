import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import trainer from 'assets/trainer.jpeg';
import green from 'assets/green.jpeg';
import project2 from 'assets/project2.jpeg';
import './Projects.scss';

const Projects = () => {

    const navigate = useNavigate();
    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('projects.title');
        ref.current.style.backgroundImage = `url(${green})`;
    }, []);

    return (
        <>
            <div className="Projects__container">
                <div className="Projects__container__image" ref={ref}>
                    <div className="Projects__container__image__cover"></div>
                    <div className="Projects__container__content__title">
                        <h1>{t('projects.title')}</h1>
                    </div>
                </div>
                <div className="Projects__container__content">
                    <div className="Projects__container__content__projects">
                        <div className="Projects__container__content__projects__project">
                            <div className="Projects__container__content__projects__project__image">
                                <img src={trainer} alt="project1" />
                            </div>
                            <div className="Projects__container__content__projects__project__content">
                                <div className="Projects__container__content__projects__project__content__title">
                                    <h3>
                                        {t('projects.project1.title')}
                                    </h3>
                                </div>
                                <div className="Projects__container__content__projects__project__content__text">
                                    <p>
                                        {t('projects.project1.description')}
                                    </p>
                                    <div className="Projects__container__content__projects__project__content__text__button">
                                        <button onClick={() => {
                                            // Download file
                                            const link = document.createElement('a');
                                            link.href = t('projects.project1.pdf');
                                            link.setAttribute('download', t('projects.project1.pdf').substring(t('projects.project1.pdf').lastIndexOf('/') + 1));
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}>
                                            {t('projects.project1.button')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Projects__container__content__projects__project reverse">
                            <div className="Projects__container__content__projects__project__content">
                                <div className="Projects__container__content__projects__project__content__title">
                                    <h3>
                                        {t('projects.project2.title')}
                                    </h3>
                                </div>
                                <div className="Projects__container__content__projects__project__content__text">
                                    {
                                        Array.isArray(t('projects.project2.description', { returnObjects: true })) ?
                                            t('projects.project2.description', { returnObjects: true }).map((item, index) => {
                                                return (
                                                    index === 0 ? <p key={index}>{item}</p> : null
                                                )
                                            })
                                            :
                                            <p>{t('projects.project2.description')}</p>

                                    }
                                    <div className="Projects__container__content__projects__project__content__text__button">
                                        <button onClick={() => {
                                            navigate('/projects/' + t('projects.project2.slug') + '/');
                                        }}>
                                            {t('projects.project2.button')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="Projects__container__content__projects__project__image">
                                <img src={project2} alt="project2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Projects;
