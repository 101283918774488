
const SpanishAboutTranslation = {
    title : "Sobre nosotros",
    values : {
        title : "Nuestros valores",
    },
    team : {
        title : "Nuestro equipo",
        button : "Saber más",
        "ceo-founder" : {
            name : "Malad",
            surname : "M.Sidi",
            position : "Presidente y fundador",
        },
        "communication-manager" : {
            name : "Nuria",
            surname : "Soriano",
            position : "Directora de comunicación",
        },
        "international-cooperation-manager" : {
            name : "Hanini",
            surname : "Mulai",
            position : "Director de cooperación internacional",
        },
        "web-developer" : {
            name : "Jose Luis",
            surname : "Silvestre",
            position : "Responsable de tecnología y diseño gráfico",
        },
    }
};

export default SpanishAboutTranslation;