import English from './english';
import Spanish from './spanish';

const languages = {
    en : {
        translation : English
    },
    es : {
        translation : Spanish
    }
};

export default languages;