import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import './ProjectDisplay.scss';

const ProjectDisplay = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const ref = useRef(null);
    const { t } = useTranslation();
    const [project, setProject] = useState("");

    useEffect(() => {
        const projects = t(`projects`, { returnObjects: true });
        let found = false;
        for (const key in projects) {
            const element = projects[key];
            if (element.slug === id) {
                setProject(key);
                found = true;
                document.title = t('projects.' + key + ".title") + " | Save Solidarity";
                ref.current.style.backgroundImage = `url(${element.img})`;
            }
        }
        if (!found) {
            navigate('/projects');
        }
    }, [id, navigate, t]);

    return (
        <>
            <div className="ProjectDisplay__container">
                <div className="ProjectDisplay__container__image" ref={ref}>
                    <div className="ProjectDisplay__container__image__cover"></div>
                    <div className="ProjectDisplay__container__content__title">
                        <h1>{t('projects.' + project + ".title")}</h1>
                    </div>
                </div>
                <div className="ProjectDisplay__container__content">
                    <div className="ProjectDisplay__container__content__projects">
                        <div className="ProjectDisplay__container__content__projects__project">
                            {
                                Array.isArray(t('projects.' + project + ".description", { returnObjects: true })) ?
                                t('projects.' + project + ".description", { returnObjects: true }).map((element, index) => {
                                    return (
                                        <div key={index} className='ProjectDisplay__container__content__projects__project__content'>
                                            <p>{element}</p>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectDisplay;
