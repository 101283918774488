
const EnglishAboutTranslation = {
    title : "About Us",
    values : {
        title : "Our Values",
    },
    team : {
        title : "Our Team",
        button : "Know more",
        "ceo-founder" : {
            name : "Malad",
            surname : "M.Sidi",
            position : "Founder & CEO",
        },
        "communication-manager" : {
            name : "Nuria",
            surname : "Soriano",
            position : "Communication Manager",
        },
        "international-cooperation-manager" : {
            name : "Hanini",
            surname : "Mulai",
            position : "International Cooperation Manager",
        },
        "web-developer" : {
            name : "Jose Luis",
            surname : "Silvestre",
            position : "Technology and Graphic Design Manager",
        },
    }
};

export default EnglishAboutTranslation;