import './EducationSlide.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import education from "assets/education.png"
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper';

const EducationSlide = () => {

    const ref = useRef(null);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${education})`;
    }, []);

    return (
        <div className="EducationSlide__container" id='EducationSlide' >
            <div className="EducationSlide__content">
                <div className="EducationSlide__content__h2">
                    <h2>{t('home.education.title')}</h2>
                </div>
                <div className="EducationSlide__content__text">
                    <p>{t('home.education.text1')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.education.textM1')}</p>
                            :
                            null
                    }
                    <div className="EducationSlide__content__quote__container">
                        <Swiper
                            spaceBetween={2}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: true
                            }}
                            pagination={{
                                clickable: true
                            }}
                            className="quoteSwiper"
                            modules={[Autoplay, Pagination]}
                        >
                            <SwiperSlide>
                                <div className="EducationSlide__content__quote">
                                    <blockquote className="EducationSlide__content__quote">
                                        {t('home.education.quotes.1.quote')}
                                        <span>-- {t('home.education.quotes.1.author')}</span>
                                    </blockquote>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="EducationSlide__content__quote">
                                    <blockquote className="EducationSlide__content__quote">
                                        {t('home.education.quotes.2.quote')}
                                        <span>--{t('home.education.quotes.2.author')}</span>
                                    </blockquote>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    {
                        !isMobile ?
                            <p>{t('home.education.textM2')}</p>
                            :
                            null
                    }
                </div>
            </div>
            <div className="EducationSlide__image" ref={ref}></div>
            <div className="EducationSlide__image dark"></div>
        </div>
    );

}

export default EducationSlide;