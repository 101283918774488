

const ProjectsTranslationSpanish = {
    title: "Proyectos",
    project1: {
        title: "Formación de Formadores",
        description: "Este proyecto está siendo desarrollado por SaveSolidarity junto a Olympe Abogados y el Ministerio de Juventud y Deportes del Sáhara Occidental. El objetivo es mejorar la figura y el estatus de las mujeres saharauis mediante la adquisición de empoderamiento económico y representación de paridad en la sociedad.",
        button: "Ver detalles",
        img: '/img/projects/project1.jpeg',
        pdf: '/docs/projects/project1.pdf',
    },
    project2: {
        title: "Nutrición para el Sáhara Occidental",
        description: [
            "Durante cinco días, Mari Cruz Manzaneque  y Lydia Serrano, ambas Dietistas-Nutricionistas  y doctorandas de diferentes universidades (Universidad de Valencia y universidad  complutense  de Madrid) han estado en los campamentos. El objetivo de su visita ha sido convivir con la comunidad saharaui para identificar necesidades educativas en materia de nutrición sin olvidar la cultura originaria del pueblo saharaui.",
            "Gracias a la asociación Save Solidarity se ha conseguido esta inmersión cultural donde se ha conocido de la mano de los propios saharauis, el contexto, los agentes,  recursos y valores alimentarios de los campamentos de Tindouf. Con todo ello se logrará conseguir realizar un estudio para la promoción de la cultura alimentaria saharaui a través de un programa de educación nutricional en los diferentes niveles de los campamentos.",
            "Las actividades desarrolladas han sido las siguientes:",
            "Martes 14 de febrero. Se visitaron los comercios que fueron apoyados por microproyectos concedidos por la asociación. Entre ellos una frutería, una carnicería y una panadería. Otros comercios locales apoyados por el programa mundial de alimentos, también fueron visitados. Para finalizar la mañana, Mari Cruz y Lydia tuvieron  la oportunidad de visitar el centro sanitario de Awser y conversar con el equipo de matronas y el sanitario responsable del centro. Se abordaron temas sobre la alimentacion de niños, embarazadas y lactantes. Especialmente se atendió a los suplementos facilitados y las dificultades en su distribución, así como a las recomendaciones que se dan para los principales problemas sanitarios como la anemia y el retraso del crecimiento.",
            "Por la tarde se convivió con una familia Saharaui, celebrando la ceremonia del té y ayudando en la elaboración de la cena. De esa manera se profundizó en la cultura alimentaria saharaui.",
            "Miércoles 15 de febrero. Se desplazaron hasta Smara para compartir un espacio de diálogo con algunas familias humildes. Para ello fue necesaria la ayuda de una joven saharui con conocimientos de español, quien actuó como interprete. Se realizaron encuestas de consumo alimentario seguido de entrevistas, las cuales abordaban diferentes dimensiones de la educación y la alimentación en los campamentos. Destacar que,a pesar de los bajos recursos y la humildad de las familias, no dudaron en ofrecer el té y las galletas que tenían para ocasiones especiales derrochando hospitalidad.",
            "Pasaron la tarde en el mercado de Aswerd, donde se visitó una tienda de especias y hierbas tradicionales de uso culinario y medicinal. Para completar el día, la familia Saharaui de acogida, enseñó un juego típico saharaui: siga",
            "Jueves 16 de febrero.  Las cooperantes se desplazaron hasta Rabouni para reunirse con dos entidades importantes en temas de nutrición y alimentación (unicef y el Programa Mundial de Alimentos o PAM). Se consiguió crear un contacto con Unicef para el apoyo del programa de educación en nutrición, y con el PAM, se resolvieron dudas sobre el apoyo a la nutrición saharaui con la cesta básica y los proyectos futuros relacionados con la cultura alimentaria y nuevos estudios poblacionales. Para finalizar la jornada, el nuevo Ministro de Cultura, concedió una reunión para compartir los intereses culturales respecto la alimentación en los campamentos de Tindouf.",
            "Con esta inmersión cultural sumado a las entrevistas y reuniones realizadas, las cooperantes han recabado la suficiente información para realizar un informe que se convertirá en un proyecto."
        ],
        button: "Ver más",
        slug : "nutrition-for-the-western-sahara",
        img: '/img/projects/project2.jpeg',
    }
};

export default ProjectsTranslationSpanish;