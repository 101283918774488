
const EnglishHomeTranslation = {
    intro: {
        subtitle: "We are a non-profit organization that works for the development of the region of Tindouf and the improvement of the living conditions of the local population.",
    },
    cooperation: {
        title: "International Cooperation",
        text1: "In SaveSolidarity, we are compromissed with the development of the needy countries and the improvement of the living conditions of the local population, focusing on the Western Sahara.",
        textM1: "Our work is destined to satisfy the needs of the local population, through the implementation of projects that contribute to the development of the region and the improvement of the living conditions in matter of education, gender equality, sport and access and management of hydric resources.",
        text2: " We are convinced that the best way to achieve this is through the cooperation with the local population and institutions from all across the globe.",
        textM2: "Also, we are committed to the promotion of the values of solidarity, equality and respect for human rights, as well as the promotion of the values of tolerance, peace and respect for the environment.",
        button: "Discover our projects",
    },
    education: {
        title: "Education",
        text1: "Education is one of the fundamental pillars for the development of a society. In SaveSolidarity, we believe that education is the key to the development of the region of Tindouf and the improvement of its living conditions.",
        textM1: "In a region where the rate of illiteracy is 80%, education and training are pending areas of development and in need of economic and human resources.",
        quotes: {
            1: {
                quote: "Knowledge is power. Information is liberating. Education is the basis of progress, in society and in the family.",
                author: "Kofi Annan",
            },
            2: {
                quote: "Education is the most powerful weapon you can use to change the world.",
                author: "Nelson Mandela",
            },
        },
        textM2: "Therefore, we have set ourselves the goal of guaranteeing the access to education of the population of the region of Tindouf, through the implementation of education and training projects, as well as eliminating the barriers that other needs may represent for the achievement of this objective.",
    },
    gender: {
        title: "Gender Equality",
        text1: "Gender equality is a critical issue in Western Sahara, where women and girls often face significant barriers to accessing education, healthcare, and employment opportunities.",
        textM1: "Women and girls play an important role in Western Sahara's economy, yet their potential is often unrealized due to discrimination and lack of access to resources and opportunities.",
        text2: "In order to promote gender equality in Western Sahara, it is important to address the root causes of gender-based discrimination without falling into the trap of imposing European or Western values on the local population.",
        textM2: "In SaveSolidarity, we are working with the government and private corporations who are as committed as we are in improving women's lives in Western Sahara.",
        button: "Know more about our latest project",
    },
    sport: {
        title: "Sport",
        text1: "Sport is a fundamental aspect for the physical and mental health of populations all over the world, and Western Sahara is no exception. Furthermore, sport can have a positive impact in building stronger and more united communities.",
        textM1: "However, in Western Sahara, access to sporting facilities and opportunities to participate in sports activities can be limited. This can be especially difficult for girls and women, who often face additional barriers to participating in sports activities.",
        text2: "At Save Solidarity, we are committed to promoting sport in Western Sahara, and we are working to ensure that all people, regardless of their gender, have access to sporting facilities and opportunities to participate in sports activities.",
        textM2: "Our goal is to create stronger and more united communities through sport and to promote inclusion and equal opportunities for all residents of Western Sahara.",
    },
    water: {
        title: "Hydric Resources",
        text1: "Access to water is a fundamental human right. At Save Solidarity, we believe that access to water and management of hydric resources is a necessity for the survival and development of refugee camps, as well as for the entire Tindouf region and Western Sahara.",
        textM1: "The region is one of the driest in the world, and available hydric resources are scarce. Most of the population relies on wells and deep wells for water, but these sources are often contaminated with bacteria and parasites that can cause diseases.",
        text2: "In a region where four out of ten people do not have access to potable water, it is imperative to create an infrastructure that guarantees access to potable water for all the population, as well as management of the hydric resources necessary for agriculture and livestock.",
        textM2: "This includes the implementation of water sanitation programs and the construction of infrastructure for the treatment and distribution of potable water. International efforts are also required to address water scarcity in the region and ensure equal access to hydric resources.",
    }

};

export default EnglishHomeTranslation;