import ContactForm from "components/ContactForm/ContactForm";
import ContactMedium from "components/ContactMedium/ContactMedium";
import { useTranslation } from "react-i18next";
import "./Contact.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'

const Contact = () => {

    const { t } = useTranslation();
    document.title = t("contact.title") + " | Save Solidarity";

    return (
        <div className="Contact__container">
            <div className="Contact__container__map">
                <MapContainer center={[27.494476, -7.9321729, 14.96]} zoom={12} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; Save Solidarity'
                        url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </MapContainer>
            </div>
            <div className="Contact__container__rest">
                <h1>{t("contact.title")}</h1>
                <div className="Contact__container__content">
                    <ContactMedium />
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}

export default Contact;