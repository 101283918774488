

const ContactEnglishTranslation = {
    title : "Contact Us",
    email : "Email",
    phone : "Phone",
    form : {
        title : "Write us a message!",
        nameLabel : "Your name",
        namePlaceholder : "Name",
        emailLabel : "Your email",
        emailPlaceholder : "Email",
        messageLabel : "Your message",
        messagePlaceholder : "Message",
        validation : "Please fill in all the fields",
        terms : "I accept that my data will be stored and used to answer my request.",
        sent : "Sent!",
        button : "Send",
    }
};

export default ContactEnglishTranslation;
