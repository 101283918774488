import publicRoutes from "./public.routes";
//import privateRoutes from "./private.routes";
import errorRoutes from "./error.routes";

import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    ...publicRoutes,
    //...privateRoutes,
    ...errorRoutes,
]);

export default router;

