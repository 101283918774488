import EnglishHomeTranslation from "./home.translation.english";
import EnglishNavbarTranslations from "./navbar.translation.english";
import EnglishAboutTranslation from "./about.translation.english";
import EnglishContactTranslation from "./contact.translation.english";
import EnglishProjectsTranslation from "./projects.translation.english";

const englishTranslations = {
    navbar: EnglishNavbarTranslations,
    home: EnglishHomeTranslation,
    about: EnglishAboutTranslation,
    contact: EnglishContactTranslation,
    projects: EnglishProjectsTranslation,
    footer : {
        text : "Follow us"
    }
};

export default englishTranslations;