import React from 'react';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import './ContactMedium.scss';

const ContactMedium = () => {

    const { t } = useTranslation();

    return (
        <div className="ContactMedium__container">
            <div className="ContactMedium__container__item" onClick={
                () => {
                    window.location.href = "tel:+34610363343";
                }
            }>
                <div className="ContactMedium__container__item__header">
                    <AiFillPhone />
                    <h3>{t('contact.phone')}</h3>
                </div>
                <div className="ContactMedium__container__item__content">
                    <a href="tel:+34610 36 33 43">+34 610 36 33 43</a>
                </div>
            </div>
            <div className="ContactMedium__container__item" onClick={
                () => {
                    window.location.href = "mailto:contact@savesolidarity.org";
                }
            }>
                <div className="ContactMedium__container__item__header">
                    <AiFillMail />
                    <h3>{t('contact.email')}</h3>
                </div>
                <div className="ContactMedium__container__item__content">
                    <a href="mailto:contact@savesolidarity.org">contact@savesolidarity.org</a>
                </div>
            </div>
        </div>
    )
}

export default ContactMedium;