import './PeopleContainer.scss'
import { useTranslation } from 'react-i18next'
import PersonCard from 'components/PersonCard/PersonCard'
import nuria from 'assets/nuria.jpeg'
import hanini from 'assets/hanini.jpeg'
import malad from 'assets/malad.jpeg'
import jalihenna from 'assets/jalihenna.jpeg'
import webDev from 'assets/web-developer.webp'

const PeopleContainer = () => {

    const { t } = useTranslation();

    return (
        <div className="PeopleContainer__container">
            <div className="PeopleContainer__content">
                <div className="PeopleContainer__content__title">
                    <h2>{t('about.team.title')}</h2>
                </div>
                <div className="PeopleContainer__content__people">
                    <PersonCard person={{
                        name: t('about.team.ceo-founder.name'),
                        surname: t('about.team.ceo-founder.surname'),
                        position: t('about.team.ceo-founder.position'),
                        url : 'ceo-founder',
                        image: malad
                    }} />
                    <PersonCard person={{
                        name: t('about.team.international-cooperation-manager.name'),
                        surname: t('about.team.international-cooperation-manager.surname'),
                        position: t('about.team.international-cooperation-manager.position'),
                        url : 'international-cooperation-manager',
                        image: hanini
                    }} />
                    <PersonCard person={{
                        name: t('about.team.communication-manager.name'),
                        surname: t('about.team.communication-manager.surname'),
                        position: t('about.team.communication-manager.position'),
                        url : 'communication-manager',
                        image: nuria
                    }} />
                    <PersonCard person={{
                        name: t('about.team.web-developer.name'),
                        surname: t('about.team.web-developer.surname'),
                        position: t('about.team.web-developer.position'),
                        url : 'web-developer',
                        image: webDev
                    }} />
                </div>
            </div>
        </div>
    )
}


export default PeopleContainer;

