import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import './PersonCard.scss';

const PersonCard = ({ person }) => {

    const imageRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.style.backgroundImage = `url(${person.image})`;
        }
    }, [person.image]);

    // function redirect() {
    //     window.location.href = `/about/${person.url}`;
    // }

    return (
        <div className="PersonCard__container">
            <div className="PersonCard__image" ref={imageRef}>
            </div>
            <div className="PersonCard__content">
                <h3 className="PersonCard__name">{person.name} {person.surname}</h3>
                <p className="PersonCard__position">{person.position}</p>
                {/* <button className="PersonCard__button" onClick={redirect}>
                    {t("about.team.button")}
                </button> */}
            </div>
        </div>
    );

}

export default PersonCard;