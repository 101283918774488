

const SpanishNavbarTranslation = {

    "home": "Inicio",
    "about": "Sobre nosotros",
    "contact": "Contacto",
    "projects": "Proyectos"

}

export default SpanishNavbarTranslation;