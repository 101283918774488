import './Slide2.scss';
import { useTranslation } from 'react-i18next';
import { FaHands } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';
import { BsGenderAmbiguous } from 'react-icons/bs';
import { MdOutlineSportsHandball } from 'react-icons/md';
import { FaHandHoldingWater } from 'react-icons/fa';

const Slide2 = ({scrollToSlide}) => {

    const { t } = useTranslation();

    return (
        <div className="Slide2__container">
            <div className="Slide2__content">
                <div className="Slide2__content__element" onClick={() => scrollToSlide(2)}>
                    <FaHands className="Slide2__content__element__icon" />
                    <div className="Slide2__content__element__text">
                        {t('home.cooperation.title')}
                    </div>
                </div>
                <div className="Slide2__content__element" onClick={() => scrollToSlide(3)}>
                    <MdSchool className="Slide2__content__element__icon" />
                    <div className="Slide2__content__element__text">
                        {t('home.education.title')}
                    </div>
                </div>
                <div className="Slide2__content__element" onClick={() => scrollToSlide(4)}>
                    <BsGenderAmbiguous className="Slide2__content__element__icon" />
                    <div className="Slide2__content__element__text">
                        {t('home.gender.title')}
                    </div>
                </div>
                <div className="Slide2__content__element" onClick={() => scrollToSlide(5)}>
                    <MdOutlineSportsHandball className="Slide2__content__element__icon" />
                    <div className="Slide2__content__element__text">
                        {t('home.sport.title')}
                    </div>
                </div>
                <div className="Slide2__content__element" onClick={() => scrollToSlide(6)}>
                    <FaHandHoldingWater className="Slide2__content__element__icon" />
                    <div className="Slide2__content__element__text">
                        {t('home.water.title')}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Slide2;