import SpanishHomeTranslation from "./home.translation.spanish";
import SpanishNavbarTranslation from "./navbar.translation.spanish";
import SpanishAboutTranslation from "./about.translation.spanish";
import SpanishContactTranslation from "./contact.translation.spanish";
import SpanishProjectsTranslation from "./projects.translation.spanish";

const spanishTranslations = {
    navbar: SpanishNavbarTranslation,
    home: SpanishHomeTranslation,
    about: SpanishAboutTranslation,
    contact: SpanishContactTranslation,
    projects: SpanishProjectsTranslation,
    footer : {
        text : "Síguenos"
    }
};

export default spanishTranslations;