import './ScrollTop.scss';
import { SlArrowUp } from 'react-icons/sl';

const ScrollTop = ({activeSlide, scrollToSlide}) => {

    return (
        <>
            {
                activeSlide > 1 ?
                    <div className="ScrollTop__container" onClick={() => {
                        scrollToSlide(1);
                    }}>
                        <div className="ScrollTop__button">
                            <SlArrowUp className="ScrollTop__icon" />
                        </div>
                    </div>
                    :
                    null
            }
        </>

    );

};

export default ScrollTop;