import './Home.scss';
import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Slide1 from 'components/Slide1/Slide1';
import Slide2 from 'components/Slide2/Slide2';
import CooperationSlide from 'components/CooperationSlide/CooperationSlide';
import EducationSlide from 'components/EducationSlide/EducationSlide';
import GenderSlide from 'components/GenderSlide/GenderSlide';
import SportSlide from 'components/SportSlide/SportSlide';
import WaterSlide from 'components/WaterSlide/WaterSlide';
import ScrollTop from 'components/ScrollTop/ScrollTop';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Mousewheel } from "swiper";

const Home = () => {

    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    document.title = "Save Solidarity";

    function scrollToSlide(index) {
        if (swiper) {
            swiper.slideTo(index, 900, false);
        }
    }

    return (
        <>
            <ScrollTop activeSlide={activeSlide} scrollToSlide={scrollToSlide}/>
            <Swiper
                direction='vertical'
                slidesPerView={1}
                resistance={'100%'}
                resistanceRatio={0}
                spaceBetween={0}
                speed={900}
                mousewheel={true}
                pagination={{
                    "clickable": true
                }}
                className="mySwiper"
                modules={[Mousewheel]}
                onSwiper={(swiper2) => {
                    if (!swiper) {
                        setSwiper(swiper2);
                    }
                }}
                onSlideChange={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    setActiveSlide(swiper.activeIndex);
                }}
            >
                <SwiperSlide>
                    <Slide1 scrollToSlide={scrollToSlide} />
                </SwiperSlide>
                <SwiperSlide>
                    <Slide2 scrollToSlide={scrollToSlide} />
                </SwiperSlide>
                <SwiperSlide>
                    <CooperationSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <EducationSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <GenderSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <SportSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <WaterSlide />
                </SwiperSlide>
            </Swiper>
        </>
    );

}

export default Home;