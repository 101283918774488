import React from 'react';
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import './MainLayout.scss';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <div className="main-layout">
        <Navbar />
        <Outlet />
        <Footer />
    </div>
  );
}

export default MainLayout;