import React from 'react';
import './TeamDetails.scss';
import { useParams } from 'react-router-dom';

const TeamDetails = () => {

    const { memberUrl } = useParams();

    return (
        <div className="TeamDetails__container">

        </div>
    )

}

export default TeamDetails;