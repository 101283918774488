

const ProjectsTranslationEnglish = {
    title: "Projects",
    project1: {
        title: "Trainers Training",
        description: "This project is being developed by SaveSolidarity alongside Olympe Abogados and the Ministry of Youth and Sports of the Western Sahara. The objective is to improve the figure and status of the Saharawi women by adquiring economic empowerment and parity representation in society.",
        button: "More info",
        img: '/img/projects/project1.jpeg',
        pdf: '/docs/projects/project1.pdf',
    },
    project2: {
        title: "Nutrition for the Western Sahara",
        description: [
            "For five days, Mari Cruz Manzaneque and Lydia Serrano, both Dietitians-Nutritionists and doctoral candidates from different universities (University of Valencia and Complutense University of Madrid) have been in the Western Sahara refugee camps. The purpose of their visit was to live with the Sahrawi community to identify educational needs in nutrition, without forgetting the original culture of the Sahrawi people.",
            "Thanks to the Save Solidarity association, this cultural immersion was achieved, where they learned about the context, agents, resources, and food values of the Tindouf camps, firsthand from the Sahrawi people. With all this, they will be able to carry out a study for the promotion of Sahrawi food culture through a nutritional education program at different levels of the camps.",
            "The activities carried out were as follows:",
            "Tuesday, February 14th. They visited the shops that were supported by microprojects granted by the association. Among them were a fruit store, a butcher shop, and a bakery. Other local businesses supported by the World Food Program were also visited. To conclude the morning, Mari Cruz and Lydia had the opportunity to visit the health center in Awser and talk to the team of midwives and the health professional in charge of the center. They addressed issues related to the feeding of children, pregnant women, and breastfeeding mothers. Special attention was paid to the supplements provided and the difficulties in their distribution, as well as the recommendations given for major health problems such as anemia and growth retardation.",
            "In the afternoon, they spent time with a Sahrawi family, celebrating the tea ceremony and helping with the preparation of dinner. This way, they deepened their knowledge of Sahrawi food culture.",
            "Wednesday, February 15th. They traveled to Smara to share a space for dialogue with some humble families. For this, the help of a young Sahrawi woman with knowledge of Spanish was needed, who acted as an interpreter. Food consumption surveys followed by interviews were conducted, addressing different dimensions of education and nutrition in the camps. It is worth noting that, despite the low resources and humility of the families, they did not hesitate to offer the tea and cookies they had for special occasions, displaying their hospitality.",
            "They spent the afternoon at the Aswerd market, where they visited a store of traditional spices and herbs used for cooking and medicinal purposes. To complete the day, the host Sahrawi family taught them a typical Sahrawi game: siga.",
            "Thursday, February 16th. The aid workers traveled to Rabouni to meet with two important entities in nutrition and food (UNICEF and the World Food Program or WFP). They managed to establish contact with UNICEF to support the nutrition education program, and with the WFP, they resolved doubts about support for Sahrawi nutrition with the basic basket and future projects related to food culture and new population studies. To conclude the day, the new Minister of Culture granted a meeting to share cultural interests regarding food in the Tindouf camps.",
            "With this cultural immersion, combined with the interviews and meetings held, the aid workers have gathered enough information to prepare a report that will become a project."
        ],
        button: "See details",
        slug : "nutrition-for-the-western-sahara",
        img: '/img/projects/project2.jpeg',
    }
};

export default ProjectsTranslationEnglish;