import MainLayout from "layouts/MainLayout/MainLayout"
import Home from "pages/Home/Home"
import About from "pages/About/About"
import Contact from "pages/Contact/Contact"
import Projects from "pages/Projects/Projects"
import TeamDetails from "components/TeamDetails/TeamDetails"
import ProjectDisplay from "components/ProjectDisplay/ProjectDisplay"

const publicRoutes = [
    {
        element: <MainLayout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/about",
                element: <About />,
            },
            {
                path: "/about/:id",
                element: <TeamDetails />,
            },
            {
                path: "/contact",
                element: <Contact />,
            },
            {
                path: "/projects",
                element: <Projects />,
            },
            {
                path: "/projects/:id",
                element: <ProjectDisplay />,
            }
        ]
    }
];

export default publicRoutes;
