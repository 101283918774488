import './SportSlide.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import sport from "assets/sports.webp"
import { useMediaQuery } from 'react-responsive';

const SportSlide = () => {

    const ref = useRef(null);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${sport})`;
    }, []);

    return (
        <div className="SportSlide__container" id='SportSlide' >
            <div className="SportSlide__content">
                <div className="SportSlide__content__h2">
                    <h2>{t('home.sport.title')}</h2>
                </div>
                <div className="SportSlide__content__text">
                    <p>{t('home.sport.text1')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.sport.textM1')}</p>
                            :
                            null
                    }
                    <p>{t('home.sport.text2')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.sport.textM2')}</p>
                            :
                            null
                    }
                </div>
            </div>
            <div className="SportSlide__image" ref={ref}></div>
            <div className="SportSlide__image"></div>
        </div>
    );

}

export default SportSlide;