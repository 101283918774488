import './WaterSlide.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import water from "assets/water.jpeg"
import water2 from "assets/water2.jpeg"
import { useMediaQuery } from 'react-responsive';

const WaterSlide = () => {

    const ref = useRef(null);
    const ref2 = useRef(null);
    const { t } = useTranslation();
    // Max width 1026px and max height 600px
    const isMobile = useMediaQuery({ query: '(max-width: 1024px), (max-height: 700px)' });

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${water})`;
        if (ref2.current) ref2.current.style.backgroundImage = `url(${water2})`;
    }, [isMobile]);

    return (
        <div className="WaterSlide__container" id='WaterSlide' >
            <div className="WaterSlide__image" ref={ref}></div>
            {
                isMobile ? <div className="WaterSlide__image dark"></div> : null
            }
            <div className="WaterSlide__content">
                <div className="WaterSlide__content__h2">
                    <h2>{t('home.water.title')}</h2>
                </div>
                <div className="WaterSlide__content__text">
                    <p>{t('home.water.text1')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.water.textM1')}</p>
                            :
                            null
                    }
                    <p>{t('home.water.text2')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.water.textM2')}</p>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WaterSlide__image2" ref={ref2}></div> : null
        </div>
    );

}

export default WaterSlide;