import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactForm.scss';
import config from 'config';

const ContactForm = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);
    };

    const validateData = () => {
        if (name === '' || email === '' || message === '') {
            alert(t('contact.form.validation'));
            return false;
        }
        return true;
    };

    const sendEmail = () => {

        if (!validateData()) {
            return;
        }

        const params = {
            SecureToken: config.email.token,
            To: config.email.to,
            From: config.email.from,
            Subject: "New message from your website",
            Body: `Name: ${name} <br/> Email: ${email} <br/> Message: ${message}`
        }
        console.log(params);
        window.Email.send(params)
            .then((message) => {
                alert(message);
                // Change button message to "Message sent"
                document.getElementById('send').innerHTML = t('contact.form.sent');
            }).catch(
                error => alert(error)
            );
    };


    return (
        <div className="ContactForm__container">
            <form onSubmit={handleSubmit}>
                <h3>{t('contact.form.title')}</h3>
                <div className='ContactForm__upper'>
                    <label htmlFor="name">{t('contact.form.nameLabel')}</label>
                    <input
                        type="text"
                        placeholder={t('contact.form.namePlaceholder')}
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="email">{t('contact.form.emailLabel')}</label>
                    <input
                        type="email"
                        placeholder={t('contact.form.emailPlaceholder')}
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='ContactForm__lower'>
                    <label htmlFor="message">{t('contact.form.messageLabel')}</label>
                    <textarea
                        placeholder={t('contact.form.messagePlaceholder')}
                        name='message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
                <div className='ContactForm__terms'>
                    <input type="checkbox" name="terms" id="terms" />
                    <label htmlFor="terms">{t('contact.form.terms')}</label>
                </div>
                <button type="submit" className='ContactForm__button' onClick={sendEmail} id='send'>
                    {t('contact.form.button')}
                </button>
            </form >
        </div >
    );
}

export default ContactForm;