import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "translations/languages";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true,
        resources: languages,
        fallbackLng: 'en',
        supportedLngs: ['en', 'es'],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;