import PeopleContainer from 'components/PeopleContainer/PeopleContainer';
import { useTranslation } from 'react-i18next';
import nosotros from 'assets/nosotros.jpeg';
import { useRef, useEffect } from 'react';
import './About.scss';

const About = () => {

    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        // Change the title of the page
        document.title = t('about.title') + ' | Save Solidarity';
        ref.current.style.backgroundImage = `url(${nosotros})`;
        ref.current.style.backgroundSize = 'cover';
        // Center at the top at 40% of the image
        ref.current.style.backgroundPosition = 'center 40%';
    }, []);

    return (
        <>
            <div className="About__container">
                <div className="About__container__image" ref={ref}>
                    <div className="About__container__image__cover"></div>
                    <div className="About__container__content__title">
                        <h1>{t('about.title')}</h1>
                    </div>
                </div>
                <div className="About__container__content">
                    {/* <Values /> */}
                    <PeopleContainer />
                </div>
            </div>
        </>
    )

}

export default About;