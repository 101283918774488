import './GenderSlide.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import gender from "assets/gender.jpeg"
import { useMediaQuery } from 'react-responsive';

const GenderSlide = () => {

    const ref = useRef(null);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${gender})`;
    }, []);

    return (
        <div className="GenderSlide__container" id='GenderSlide' >
            <div className="GenderSlide__image" ref={ref}></div>
            <div className="GenderSlide__image dark"></div>
            <div className="GenderSlide__content">
                <div className="GenderSlide__content__h2">
                    <h2>{t('home.gender.title')}</h2>
                </div>
                <div className="GenderSlide__content__text">
                    <p>{t('home.gender.text1')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.gender.textM1')}</p>
                            :
                            null
                    }
                    <button className="GenderSlide__button" onClick={() => {
                        window.location.href = "/projects";
                    }}>
                        {t('home.gender.button')}
                    </button>
                    <p>{t('home.gender.text2')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.gender.textM2')}</p>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    );

}

export default GenderSlide;