import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';
import logo from 'assets/logo.png';
import './NavbarDesktop.scss';

const NavbarDesktop = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const container = useRef(undefined);
    let mobileMenuOpen;
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    function toggleMobileMenu(e) {
        container.current.classList.toggle("NavbarDesktop__container--open");
        mobileMenuOpen = !mobileMenuOpen;
        if (mobileMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    useEffect(() => {
        document.body.style.overflow = "auto";
        // eslint-disable-next-line
        mobileMenuOpen = false;
        container.current.classList.remove("NavbarDesktop__container--open");
    }, [location.pathname]);

    return (
        <>
            <div className={"NavbarDesktop__container"} ref={container}>
                <div className="NavbarDesktop__logo" onClick={
                    () => {
                        navigate('/');
                        if (mobileMenuOpen)
                            toggleMobileMenu();
                    }
                }>
                    <img src={logo} alt="Save Solidarity Logo" />
                </div>
                <div className="NavbarDesktop__menu">
                    <div className="NavbarDesktop__menu__item__container" onClick={
                        () => {
                            toggleMobileMenu();
                            if (window.location.pathname === '/')
                                window.scrollTo(0, 0);
                        }
                    }>
                        <div className="NavbarDesktop__menu__item">
                            <NavLink to="/"
                                className={({isActive}) => 
                                    isActive?"NavbarDesktop__menu__item__container__active" : undefined
                                }>
                                {t('navbar.home')}
                            </NavLink>
                        </div>
                    </div>
                    <div className="NavbarDesktop__menu__item__container" onClick={toggleMobileMenu}>
                        <div className="NavbarDesktop__menu__item">
                            <NavLink to="/about"
                                className={({isActive}) => 
                                    isActive?"NavbarDesktop__menu__item__container__active" : undefined
                                }>
                                {t('navbar.about')}
                            </NavLink>
                        </div>
                    </div>
                    <div className="NavbarDesktop__menu__item__container" onClick={toggleMobileMenu}>
                        <div className="NavbarDesktop__menu__item">
                            <NavLink to="/projects"
                                className={({isActive}) =>
                                    isActive? "NavbarDesktop__menu__item__container__active" : undefined
                                }>
                                {t('navbar.projects')}
                            </NavLink>
                        </div>
                    </div>
                    <div className="NavbarDesktop__menu__item__container" onClick={toggleMobileMenu}>
                        <div className="NavbarDesktop__menu__item">
                            <NavLink to="/contact"
                                className={({isActive}) =>
                                    isActive? "NavbarDesktop__menu__item__container__active" : undefined
                                }>
                                {t('navbar.contact')}
                            </NavLink>
                        </div>
                    </div>
                    {/* Language selector, list with english and spanish and flags */}
                    <div className='NavbarDesktop__language__selector'>
                        <div
                            className='NavbarDesktop__language__selector__flag__english'
                            alt="English"
                            onClick={() => changeLanguage('en')}
                        >
                        </div>
                        <div
                            className='NavbarDesktop__language__selector__flag__spanish'
                            alt="Spanish"
                            onClick={() => changeLanguage('es')}
                        >
                        </div>
                    </div>
                </div>
                <div className="NavbarDesktop__closed__hamburger" onClick={toggleMobileMenu}>
                    <div className="NavbarDesktop__closed__hamburger__line"></div>
                    <div className="NavbarDesktop__closed__hamburger__line"></div>
                    <div className="NavbarDesktop__closed__hamburger__line"></div>
                </div>
                <div className="NavbarDesktop__open__hamburger" onClick={toggleMobileMenu}>
                    X
                </div>
            </div>
        </>
    );

}

export default NavbarDesktop;