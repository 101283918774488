import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import intro from "assets/intro.jpeg"
import './Slide1.scss';

function Slide1({ scrollToSlide }) {

    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${intro})`;
    }, []);

    return (
        <div className="Slide1__container" ref={ref}>
            <div className="Slide1__content">
                <h1 className="Slide1__title">
                    <span className="Slide1__title__span__save">Save</span>
                    <span className="Slide1__title__span__solidarity">Solidarity</span>
                </h1>
                <p className="Slide1__text">{t('home.intro.subtitle')}</p>
                <div className="Slide1__down__arrow" onClick={() => { scrollToSlide(1) }}>
                    <div className="Slide1__down__arrow__v">
                        v
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Slide1;