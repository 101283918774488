import { useTranslation } from 'react-i18next';
import logo from 'assets/favicon.png';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
import './Footer.scss'

const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer>
            <div className="Footer__container">
                <div className="Footer__container__left">
                    <div className="Footer__container__left__logo">
                        <img src={logo} alt="Save Solidarity Logo" />
                    </div>
                    <div className="Footer__container__left__text">
                        <p>
                            &copy; {new Date().getFullYear()} Save Solidarity
                        </p>
                    </div>
                </div>
                <div className="Footer__container__right">
                    <div className="Footer__container__right__text">
                        {t('footer.text')}
                    </div>
                    <div className="Footer__container__right__logos">
                        <div className="Footer__container__right__logo" onClick={() => {
                            window.location.href = "https://www.instagram.com/savesolidarity/";
                        }}>
                            <BsInstagram className='instagram' />
                        </div>
                        <div className="Footer__container__right__logo" onClick={() => {
                            window.location.href = "https://twitter.com/SaveSolidarity";
                        }}>
                            <BsTwitter style={{ color : "#1DA1F2"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer;
