const ContactSpanishTranslation = {
    title : "Contacta con nosotros",
    email : "Correo electrónico",
    phone : "Teléfono",
    form : {
        title : "¡Escríbenos un mensaje!",
        nameLabel : "Tu nombre",
        namePlaceholder : "Nombre",
        emailLabel : "Tu correo electrónico",
        emailPlaceholder : "Correo electrónico",
        messageLabel : "Tu mensaje",
        messagePlaceholder : "Mensaje",
        validation : "Por favor, rellena todos los campos",
        terms : "Acepto que mis datos se almacenen y se utilicen para responder a mi solicitud.",
        sent : "¡Enviado!",
        button : "Enviar",
    }
};

export default ContactSpanishTranslation;
