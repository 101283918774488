import './CooperationSlide.scss';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import coop from "assets/coop.jpeg"
import { useMediaQuery } from 'react-responsive';

const CooperationSlide = () => {

    const ref = useRef(null);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px), (max-height: 650px)' });

    useEffect(() => {
        console.log(isMobile);
    }, [isMobile]);

    useEffect(() => {
        ref.current.style.backgroundImage = `url(${coop})`;
    }, []);

    return (
        <div className="CooperationSlide__container" id='CooperationSlide' >
            <div className="CooperationSlide__image" ref={ref}></div>
            <div className="CooperationSlide__image dark"></div>
            <div className="CooperationSlide__content">
                <div className="CooperationSlide__content__h2">
                    <h2>{t('home.cooperation.title')}</h2>
                </div>
                <div className="CooperationSlide__content__text">
                    <p>{t('home.cooperation.text1')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.cooperation.textM1')}</p>
                            :
                            null
                    }
                    <button className="CooperationSlide__button" onClick={() => {
                        window.location.href = "/projects";
                    }}>
                        {t('home.cooperation.button')}
                    </button>
                    <p>{t('home.cooperation.text2')}</p>
                    {
                        !isMobile ?
                            <p>{t('home.cooperation.textM2')}</p>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    );

}

export default CooperationSlide;