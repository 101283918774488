
const SpanishHomeTranslation = {
    intro : {
        subtitle : "Somos una organización sin ánimo de lucro que trabaja por el desarrollo de la región de Tindouf y la mejora de las condiciones de vida de la población local.",
    },
    cooperation : {
        title : "Cooperación Internacional",
        text1 : "En SaveSolidarity, estamos comprometidos con el desarrollo de los países necesitados y la mejora de las condiciones de vida de la población local, centrándonos en el Sáhara Occidental.",
        textM1 : "Nuestro trabajo está destinado a satisfacer las necesidades de la población local, a través de la implementación de proyectos que contribuyan al desarrollo de la región y la mejora de las condiciones de vida en materia de educación, igualdad de género, deporte y acceso y gestión de recursos hídricos.",
        text2 : "Estamos convencidos de que la mejor manera de lograr esto es a través de la cooperación con la población local e instituciones de todo el mundo.",
        textM2: "Además, nos comprometemos a la promoción de los valores de solidaridad, igualdad y respeto de los derechos humanos, así como la promoción de los valores de tolerancia, paz y respeto al medio ambiente.",
        button : "Descubre nuestros proyectos",
    },
    education : {
        title : "Educación",
        text1 : "La educación es uno de los pilares fundamentales para el desarrollo de una sociedad. En SaveSolidarity, creemos que la educación es la clave para el desarrollo de la región de Tindouf y la mejora de sus condiciones de vida.",
        textM1 : "En una región donde la tasa de analfabetismo es del 80%, la educación y la formación son áreas pendientes de desarrollo y necesitadas de recursos económicos y humanos.",
        quotes : {
            1: {
                quote : "El conocimiento es poder. La información es liberadora. La educación es la base del progreso, en la sociedad y en la familia.",
                author : "Kofi Annan",
            },
            2 : {
                quote : "La educación es el arma más poderosa que puedes usar para cambiar el mundo.",
                author : "Nelson Mandela",
            },
        }, 
        textM2 : "Por ello, nos hemos propuesto garantizar el acceso a la educación de la población autóctona de la región de Tindouf, a través de la implementación de proyectos de educación y formación, así como eliminar las barreras que otras necesidades puedan suponer para la consecución de este objetivo.",

    },
    gender: {
        title: "Igualdad de género",
        text1: "La igualdad de género es un problema crítico en el Sáhara Occidental, donde las mujeres y niñas a menudo enfrentan barreras significativas para acceder a la educación, la atención médica y las oportunidades de empleo.",
        textM1: "Las mujeres y niñas desempeñan un papel importante en la economía del Sáhara Occidental, pero su potencial a menudo se queda sin aprovechar debido a la discriminación y la falta de acceso a recursos y oportunidades.",
        text2: "Para promover la igualdad de género en el Sáhara Occidental, es importante abordar las causas raíces de la discriminación de género sin caer en la trampa de imponer valores europeos o occidentales a la población local.",
        textM2: "En Save Solidarity, estamos trabajando con el gobierno y las corporaciones privadas que están tan comprometidos como nosotros en mejorar la vida de las mujeres en el Sáhara Occidental.",
        button: "Conoce más sobre nuestro último proyecto",
    },
    sport: {
        title: "Deporte",
        text1: "El deporte es un aspecto fundamental para la salud física y mental de la población en todo el mundo, y el Sáhara Occidental no es una excepción. Además, el deporte puede tener un impacto positivo en la construcción de comunidades más fuertes y unidas.",
        textM1: "Sin embargo, en el Sáhara Occidental, el acceso a instalaciones deportivas y oportunidades para participar en actividades deportivas puede ser limitado. Esto puede ser especialmente difícil para niñas y mujeres, quienes a menudo enfrentan barreras adicionales para participar en actividades deportivas.",
        text2: "En Save Solidarity, estamos comprometidos con la promoción del deporte en el Sáhara Occidental, y estamos trabajando para asegurar que todas las personas, independientemente de su género, tengan acceso a instalaciones deportivas y oportunidades para participar en actividades deportivas.",
        textM2: "Nuestro objetivo es crear comunidades más fuertes y unidas a través del deporte, y fomentar la inclusión y la igualdad de oportunidades para todos los habitantes del Sáhara Occidental.",
    },
    water : {
        title : "Recursos hídricos",
        text1 : "El acceso al agua es un derecho humano fundamental. En Save Solidarity, creemos que el acceso al agua la gestión de los recursos hídricos es una necesidad para la supervivencia y el desarrollo de los campos de refugiados, así como para toda la región de Tindouf y el Sáhara Occidental.",
        textM1 : "La región es una de las más secas del mundo, y los recursos hídricos disponibles son escasos. La mayoría de la población depende de pozos y pozos profundos para obtener agua, pero estos fuentes a menudo se contaminan con bacterias y parásitos que pueden causar enfermedades.",
        text2 : "En una región donde cuatro de cada diez personas no tienen acceso al agua potable, es imperativo crear una infraestructura que garantice el acceso al agua potable a toda la población, así como la gestión de los recursos hídricos necesarios para la agricultura y la ganadería.",
        textM2 : "Esto incluye la implementación de programas de saneamiento de agua y la construcción de infraestructuras para el tratamiento y la distribución de agua potable. También se requieren esfuerzos internacionales para abordar la escasez de agua en la región y garantizar un acceso equitativo a los recursos hídricos.",
    }
};

export default SpanishHomeTranslation;